<template>
  <b-card
    class="workflow-edit-wrapper"
  >
    <!-- form -->
    <b-form id="workflowForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="编号"
            label-for="workflow_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="workflow_no"
              size="sm"
              v-model="workflow.workflow_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="workflow_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="workflow_name"
              size="sm"
              v-model="workflow.workflow_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="返回ID"
            label-for="request_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="request_id"
              size="sm"
              v-model="workflow.request_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="类型"
            label-for="workflow_type"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="workflow_type"
              size="sm"
              v-model="workflow.workflow_type"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="workflow_status"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="workflow_status"
              size="sm"
              v-model="workflow.workflow_status"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="workflow.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="备注"
            label-for="remark"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="remark"
              size="sm"
              v-model="workflow.remark"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="附件"
            label-for="attachement"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="attachement"
              size="sm"
              v-model="workflow.attachement"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="workflow.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加人"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="workflow.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改时间"
            label-for="modify_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modify_time"
              size="sm"
              v-model="workflow.modify_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改人"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="workflow.updator"
            />
          </b-form-group>
        </b-col>
                <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="下拉框"
            label-for="select"
            label-size="sm"
            class="mb-1"
          >
            <v-select
              id="select"
              v-model="workflow.select"
              :options="selectOptions"
              class="select-size-sm"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import workflowStore from './workflowStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      workflow: ref({}),
      selectOptions: [
        {value: '1', label: '111'},
        {value: '2', label: '222'},
        {value: '3', label: '333'},
      ],
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('workflow')) store.registerModule('workflow', workflowStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('workflow')) store.unregisterModule('workflow')
    })

    const edit = function() {
      store.dispatch('workflow/edit', {id: this.id}).then(res => {
        this.workflow = res.data.data
      })
    }

    const view = function() {
      store.dispatch('workflow/view', {id: this.id}).then(res => {
        this.workflow = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('workflow/save', this.workflow).then(res => {
        if (res.data.code==0){
          toast.success('数据已保存!')
          this.$router.push({ name: 'apps-workflow-list'});
        }else {
          toast.error("保存失败!")
        }

      })
    }

    return {
      edit,
      view,
      cancel,
      save,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>